
export default {
  name: 'TheConpeek',

  head () {
    return {
      script: [
        {
          src: 'https://tenantpluginapiserver41.cloud.conpeek.com/cdn/conpeekViewController.js',
          body: true,
          defer: true,
        },
      ],

      link: [
        {
          rel: 'stylesheet',
          href: 'https://tenantpluginapiserver41.cloud.conpeek.com/cdn/iframe.css',
          body: true,
          defer: true,
        },
      ],
    }
  },

  mounted () {
    window.onload = function () {
      const conpeekPlugin = document.createElement('iframe')
      const config = {
        url: 'https://tenantpluginapiserver41.cloud.conpeek.com',
        plugin_id: 'ea9c3c48-0246-44be-958b-c0962de84ea7',
        media_tag: 'conpeek_plugin_video_box',
      }

      window.addEventListener('message', (event) => {
        if (event.origin.startsWith(config.url)) {
          window.getPluginInternalMessage(conpeekPlugin, event, config)
        }
      })

      conpeekPlugin.setAttribute('id', 'conpeek_plugin_iframe')
      conpeekPlugin.setAttribute('src', 'https://tenantpluginapiserver41.cloud.conpeek.com/cdn')
      conpeekPlugin.setAttribute('allowtransparency', 'true')
      conpeekPlugin.setAttribute('allowfullscreen', 'true')
      conpeekPlugin.setAttribute('frameBorder', '0')
      conpeekPlugin.setAttribute('class', 'conpeek_iframe_plugin')
      conpeekPlugin.setAttribute('allow', 'camera;microphone')
      document.body.appendChild(conpeekPlugin)
    }
  },
}
